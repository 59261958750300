// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Location from "../../blocks/location/src/Location";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import SummaryCard from "../../blocks/SummaryCard/src/SummaryCard";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Maps from "../../blocks/maps/src/Maps";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import OrderCreation5 from "../../blocks/OrderCreation5/src/OrderCreation5";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Payments from "../../blocks/Payments/src/Payments";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import DeliveryEstimator13 from "../../blocks/DeliveryEstimator13/src/DeliveryEstimator13";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Analytics from "../../blocks/analytics/src/Analytics";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ShippingAddressValidation from "../../blocks/ShippingAddressValidation/src/ShippingAddressValidation";
import Catalogue from "../../blocks/catalogue/src/Catalogue";



const routeMap = {
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Location:{
 component:Location,
path:"/Location"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
SummaryCard:{
 component:SummaryCard,
path:"/SummaryCard"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Maps:{
 component:Maps,
path:"/Maps"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
OrderCreation5:{
 component:OrderCreation5,
path:"/OrderCreation5"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
Payments:{
 component:Payments,
path:"/Payments"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
DeliveryEstimator13:{
 component:DeliveryEstimator13,
path:"/DeliveryEstimator13"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ShippingAddressValidation:{
 component:ShippingAddressValidation,
path:"/ShippingAddressValidation"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;